import React from "react";

const Players = ({players}) => {
    return (
            <div class="row">
            {players.map((player) => (
                <div class="col-lg-4">
                <div class="team-member">
                    <img class="mx-auto rounded-circle" src="assets/img/team/messi-ronaldo.png" alt="..." />
                    <h4>{player.first_name} {player.last_name}</h4>
                    {/*
                    <p class="text-muted">Lead Designer</p>
                    
                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-dark btn-social mx-2" href="#!"><i class="fab fa-linkedin-in"></i></a>
            */}
                </div>
                </div>
                
            ))}
            </div>
       
    )
}

export default Players